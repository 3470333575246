@import "~bootstrap/scss/bootstrap";

@font-face {
    font-family: 'Merck';
    src: url("../images/flags/fonts/Merck.ttf");
}

@font-face {
    font-family: 'Verdana Light';
    src: url("../images/flags/fonts/Ascender-VerdanaPro-Light.otf");
}

@font-face {
    font-family: 'Verdana Pro';
    src: url("../images/flags/fonts/Ascender-VerdanaPro-SemiBold.otf");
}

#lang {
    position: absolute;
    width: 150px;
    right: 20px;
    top: 20px;
    .select2-container--default .select2-selection--single {
        border: none!important;
    }
}